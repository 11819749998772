var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"div-content1",style:({
    transformOrigin: 'center top',
    transform: ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
    '-webkit-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
    '-moz-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
    '-o-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
    '-ms-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
  }),attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(7, 6, 43, 0.5)"}},[_c('div',{staticClass:"u-flex-col u-col-center"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"head_left",on:{"click":function($event){return _vm.$router.push('/visual_analysis/')}}}),_c('div',{staticClass:"head_center"},[_vm._v("学生信息监控")]),_c('div',{staticClass:"head_right"},[_vm._v(_vm._s(_vm.timeData))])]),_c('div',{staticClass:"visual_box"},[_c('div',{staticClass:"visual_box_left"},[_c('ChartModel',{attrs:{"title":"整体任务完成度"}},[_c('template',{slot:"chart"},[_c('LineChart',{attrs:{"height":"100%","width":"100%","chartData":[
                { Key: '收益', Value: 10 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 80 } ]}})],1)],2),_c('ChartModel',{attrs:{"title":"整体项目收益情况"}},[_c('template',{slot:"chart"},[_c('ArrayBar1',{attrs:{"height":"100%","width":"100%","chartData":[]}})],1)],2),_c('ChartModel',{attrs:{"title":"项目订单数量情况"}},[_c('template',{slot:"chart"},[_c('StudentBarChart',{attrs:{"height":"100%","width":"100%","chartData":{
                label: ['10-20', '10-21', '10-22', '10-23'],
                planData: [10, 20, 34, 99],
                actualData: [10, 20, 34, 99],
              }}})],1)],2)],1),_c('div',{staticClass:"visual_box_center"},[_vm._m(0),_c('div',{staticClass:"histogram"},[_c('ChartModel',{attrs:{"title":"总体进度"}},[_c('template',{slot:"chart"},[_c('ArrayBar1',{attrs:{"height":"100%","width":"100%","chartData":[]}})],1)],2)],1),_c('div',{staticClass:"pieBox"},[_c('div',{staticClass:"headBox mt-12"},[_vm._v("分布")]),_c('div',{staticClass:"studentStudents"},[_c('div',{staticClass:"chartBox mr-41"},[_c('StaffStudentChart',{attrs:{"height":"100%","width":"100%","chartData":[
                  { Key: '老师占比', Value: 35 },
                  { Key: '学生占比', Value: 65 } ]}}),_vm._m(1)],1),_c('div',{staticClass:"chartBox mr-41"},[_c('StaffStudentChart',{attrs:{"height":"100%","width":"100%","chartData":[
                  { Key: '老师占比', Value: 35 },
                  { Key: '学生占比', Value: 65 } ]}}),_vm._m(2)],1),_c('div',{staticClass:"chartBox"},[_c('StaffStudentChart',{attrs:{"height":"100%","width":"100%","chartData":[
                  { Key: '老师占比', Value: 35 },
                  { Key: '学生占比', Value: 65 } ]}}),_vm._m(3)],1)])])]),_c('div',{staticClass:"visual_box_right"},[_c('ChartModel',{attrs:{"title":"学生工时和产出率"}},[_c('template',{slot:"chart"},[_c('ArrayBar1',{attrs:{"height":"250px","width":"100%","chartData":[]}})],1)],2),_c('ChartModel',{attrs:{"title":"学生在线培训情况"}},[_c('template',{slot:"chart"},[_c('LineChart',{attrs:{"chartData":[
                { Key: '收益', Value: 10 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 30 },
                { Key: '收益', Value: 80 } ],"height":"100%","width":"100%"}})],1)],2),_c('ChartModel',{attrs:{"title":"生师占比情况"}},[_c('template',{slot:"chart"},[_c('ArrayBar1',{attrs:{"height":"100%","width":"100%","chartData":[]}})],1)],2)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allChart"},[_c('div',{staticClass:"chartList mr-24"},[_c('div',{staticClass:"chart_right"},[_c('div',{staticClass:"name"},[_vm._v("参与企业")]),_c('div',{staticClass:"quantity"},[_c('span',{staticClass:"number"},[_vm._v("5")]),_vm._v("个")])])]),_c('div',{staticClass:"salesVolume_left mr-24"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"quantity_txt"},[_vm._v("今日订单量")]),_c('span',{staticClass:"quantity_info"},[_vm._v("同比昨天增加")]),_c('span',{staticClass:"quantity_proportion"},[_vm._v("24%")]),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/analysis/icon_litre.png"),"alt":""}})]),_c('div',{staticClass:"quantity_number"},[_c('span',{staticClass:"number"},[_vm._v("186")]),_vm._v("个 ")])]),_c('div',{staticClass:"salesVolume_left"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"quantity_txt"},[_vm._v("今日营业额")]),_c('span',{staticClass:"quantity_info"},[_vm._v("同比昨天增加")]),_c('span',{staticClass:"quantity_proportion"},[_vm._v("24%")]),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/analysis/icon_litre.png"),"alt":""}})]),_c('div',{staticClass:"quantity_number"},[_c('span',{staticClass:"number"},[_vm._v("￥18750")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center_title mt-38"},[_c('div',{staticClass:"titleBox"},[_vm._v("学历分布")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center_title mt-38"},[_c('div',{staticClass:"titleBox"},[_vm._v("学历分布")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center_title mt-38"},[_c('div',{staticClass:"titleBox"},[_vm._v("学历分布")])])}]

export { render, staticRenderFns }